import React, { useState, useEffect } from "react";
import { PostsForm } from "../style/globalStyles";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { updateSwingUser, updateExplore, updateWellnessVideo } from "../state";
import Switch from "react-switch";
import { navigate } from "gatsby";

export const WellnessVideosPost = ({ postData = {} }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});

  useEffect(() => {
    // console.log(postData);
    if (postData) {
      setState(postData);
    } else {
      // navigate("/");
    }
    // console.log("useEffect state", postData, state);
  }, [postData]);

  //   const handleSubscribeChange = (value) => {
  //     setState({ ...state, isSubscription: value })
  //     setEnable(true)
  //   }

  const PostRender = () => {
    return (
      <PostsForm
      //   style={{ gridTemplateColumns: "1fr" }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            marginRight: "1rem",
            display: "flex",
            padding: "1em",
            borderStyle: "solid",
            borderColor: 'lightcyan',
            borderWidth: "2px",
            // height: "20rem",
            // flexDirection:'column',
            // flexWrap: "wrap",
            // alignItems: "center",
            width: "fit-content",
            // justifyContent: "space-between",
          }}
        >
          {/* <div
            style={{
              width: "14rem",
              // height: "100%",
              // alignItems: "center",
              // display: "flex",
              // justifyContent: "center",
            }}
          >
            {state.videoType === "image" ? (
              <img
                src={state.url}
                style={{
                  width: "100%",
                  // height: "fit-content",
                }}
              />
            ) : (
              <video width="100%" controls autoPlay={false}>
                <source src={state.url} type="video/mp4"></source>
                {/* <source src={state.url} type="video/ogg"></source> 
              </video>
            )}
          </div> */}
          <div
            style={{
              // marginLeft: "1rem",
              display: "flex",
              // flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "start",
              width: "26rem",
              height: "100%",
            }}
          >
            {state.videoType === "audio" ? (
              <>
              <img
                src={state.videoThumbnail}
                style={{
                  maxWidth: "100%",
                  maxHeight: "16rem",
                  marginBottom:'0.5rem'
                }}
              />
              <audio controls autoPlay={false}>
              <source src={state.url} type="audio/mp4"></source>
              </audio>
              </>
            ) : (
              <video width="100%" style={{maxHeight:'16rem'}} controls autoPlay={false}>
                <source src={state.url} type="video/mp4"></source>
                {/* <source src={state.url} type="video/ogg"></source> */}
              </video>
            )}
             <label  style={{width: '100%'}}>
              Title
              <p  style={{width: '100%'}}>
                {state.topics ? state.topics + " " + "("+(state.videoType) + ")" : "NA"}
              </p>
            </label>
            <label htmlFor="description" style={{width: '100%'}}>
              Description
              <p style={{width:'100%'}} >{state.description ? state.description : "NA"}</p>
            </label>
            <label htmlFor="languageDesc">
              Language
              <p>{state.languageDesc ? state.languageDesc : "NA"}</p>
            </label>
            <label>
              Category
              <p>{state.videoCategoryName ? state.videoCategoryName : state.videoCategory}</p>
            </label>
            <label htmlFor="customerId">
              User ID
              <p>{state.customerId}</p>
            </label>
            <label htmlFor="customerName">
              User Name
              <p>{state.user ? state.user.firstName + " " + state.user.lastName : "NA"}</p>
            </label>
            <label htmlFor="created">
              Created on
              <p>{state.created && state.created.split(" ")[0]}</p>
            </label>
            <label htmlFor="updatedOn">
              Last updated on
              <p>{state.updatedOn ? state.updatedOn.split(" ")[0] : "NA"}</p>
            </label>
            <label htmlFor="id">
              ID
              <p>{state.id ? state.id : "NA"}</p>
            </label>
            <label htmlFor="isActive">
              Status
              {/* <p>
                {state.status
                  ? state.status === 100300001
                    ? "Approved"
                    : "Not Approved"
                  : "NA"}
              </p>*/}
              <br />
              <br />
              <select
                style={{
                  width: "fit-content",
                }}
                value={state.status}
                onChange={(e) => {
                  // setstate.status(e.target.value);
                  setState({ ...state, status: Number(e.target.value) });
                  const updatePostInput = {
                    ...state,
                    status: Number(e.target.value),
                  };
                  dispatch(updateWellnessVideo(updatePostInput));
                  // setEnable(true);
                }}
              >
                <option
                  id={1}
                  value={100300001}
                  // selected={state.status === 100300001}
                >
                  Pending
                </option>
                <option
                  id={2}
                  value={100300002}
                  // selected={state.status === 100300002}
                >
                  Verified
                </option>
                <option
                  id={3}
                  value={100300003}
                  // selected={state.status === 100300003}
                >
                  Rejected
                </option>
              </select>
            </label>
          </div>
        </div>
      </PostsForm>
    );
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (enable) {
  //     setEnable(false);
  //     // console.log("ride update api request can be sent");
  //   }
  //   return;
  // };

  return (
    <>
      <PostRender />
    </>
  );
};
