import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { WellnessVideosPost } from "../components/WellnessVideosPost";
import { fetchMoreWellnessVideos } from "../state";

export const WellnessVideosComponent = ({ posts = [], currentInput = {} }) => {
  //   if (location.state === null || undefined) {
  //     // console.log(location.state)
  //   }
  const dispatch = useDispatch();
  const [wellnessVideos, setWellnessVideos] = useState(posts);

  const [currentFetchInput, setCurrentFetchInput] = useState(currentInput);

  function concatPosts(newPosts = []) {
    console.log("concat---->>>>>>>>>>>");
    if (newPosts === [] || newPosts === null) {
      return;
    }

    setWellnessVideos([...wellnessVideos, ...newPosts]);
  }
  useEffect(() => {
    // if (!loginBool) {
    //   navigate("/login");
    // }
    // if (posts.length === 0) {
    //   setWellnessVideos([]);
    // }
    // if (wellnessVideos.length === 0) {
    //   // console.log("fetchWellnessVideos being called", posts);
    //   // fetchWellnessVideos(currentFetchInput, concatPosts);
    //   setWellnessVideos(posts);
    // }
    // console.log(wellnessVideos.length, posts.length);
    // // if (currentFetchInput.videoCategory !== prevCategory) setWellnessVideos([]);
    // // currentFetchInput.videoCategory === prevCategory &&
    // //   currentFetchInput.pageNumber > 1,
    // // currentFetchInput,
    // // prevCategory
    // if (videocategories.length === 0) {
    //   //   console.log(posts);
    //   fetchVideoCategories();
    // }
  }, []);
  return (
    <div>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "flex-start",
          }}
        >
          {wellnessVideos.map((post, index) => (
            <WellnessVideosPost key={index} postData={post} />
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.5rem",
          }}
        >
          {/* <p onClick = {()=>{
                      if(currentPage === 0){
                        return null
                      }
                      if(currentPage !== 0){

                      }
                    }}
                    style={{
                      padding:'0.5rem',
                      fontSize: '1rem',
                      backgroundColor: 'gray',
                      color:'white'
                    }}>Previous</p> */}
          <p> Total posts: {wellnessVideos.length}</p>
          <p
            onClick={() => {
              // setCurrentPage(currentPage+1)

              setCurrentFetchInput({
                ...currentFetchInput,
                pageNumber: currentFetchInput.pageNumber + 1,
              });
              const morePostsInput = {
                ...currentFetchInput,
                pageNumber: currentFetchInput.pageNumber + 1,
              };
              dispatch(fetchMoreWellnessVideos(morePostsInput, concatPosts));
            }}
            style={{
              marginLeft: "1rem",
              padding: "0.5rem",
              fontSize: "1rem",
              fontWeight: "normal",
              borderRadius: "0.3rem",
              backgroundColor: "deepskyblue",
              color: "white",
              cursor: "pointer",
            }}
          >
            More Posts
          </p>
        </div>
      </>
    </div>
  );
};
